
































































































































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
import { Tabs, TabPane, Pagination, Empty, Input } from "element-ui";

@Component({
    components: {
        "al-breadcrumb": Breadcrumb,
        "el-tabs": Tabs,
        "el-tab-pane": TabPane,
        "el-pagination": Pagination,
        "el-empty": Empty,
        "el-input": Input,
    },
})
export default class ProjectListAll_2021 extends Vue {
    caseListData = []; //列表数据
    caseListOpt = {};

    loading1: any = true; //是否显示loading遮罩

    /**
     * 请求列表数据
     * @param country 国家id
     * @param field 领域id
     * @param page 页码
     * @param keyword 关键字查询
     */
    getCaseList(country: any, field: any, page: any, keyword?: any) {
        this.loading1 = true;
        this.caseListOpt = [];
        this.caseListData = [];
        const getOpt = this.$api.apiList.allProjectList;
        const opt = getOpt(country, field, page, keyword ? keyword : undefined);

        this.$axios({
            method: opt.method,
            url: opt.url,
            headers: opt.headers,
            params: opt.params,
        })
            .then((res: any) => {
                console.log(res.data.data);
                this.caseListOpt = res.data.data;
                this.caseListData = res.data.data.data;
                this.loading1 = false;
            })
            .catch(() => {
                this.loading1 = false;
            });
    }

    //点击项目跳转详情页
    handelProject(e: any, item: any, key: any) {
        this.$router.push({ name: "projectAll2021", params: { pid: item.id } });
    }

    //领域数据
    requirement: any = {};

    /**领域label */
    requirementIndex(item: any, index: string) {
        let res;
        switch (index) {
            case "field":
                res = this.$t("project_2020.field");
                break;
            case "country":
                res = this.$t("project_2020.country");
                break;
            default:
                res = index;
                break;
        }
        return res;
    }

    /**获取筛选条件 */
    async getRequirement() {
        const getOpt = this.$api.apiList.getProjectOpt;
        const opt = getOpt();

        const setRequirement = (key: string, requirement: object) => {
            this.$set(this.requirement, key, requirement);
        };

        await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        }).then((res: any) => {
            const country = [
                ...[{ id: undefined, name: "所有", name_en: "ALL" }],
                ...res.data.country,
            ];

            setRequirement("country", country);
            const field_old = res.data.field_2021;
            let field_new = [{ id: undefined, name: "所有", name_en: "ALL" }];
            for (const key in field_old) {
                if (Object.prototype.hasOwnProperty.call(field_old, key)) {
                    const el = field_old[key];
                    let element: any = {};
                    this.$set(element, "id", key);
                    this.$set(element, "name", el.zh_CN);
                    this.$set(element, "name_en", el.en);
                    field_new.push(element);
                }
            }
            setRequirement("field", field_new);
        });
    }

    requirementActive: any = {};

    /** 初始化筛选条件选中项 */
    InitRequirementActive() {
        const keyArr = Object.keys(this.requirement);
        for (const iterator of keyArr) {
            this.$set(this.requirementActive, iterator, 0);
        }
    }

    /**点击筛选条件 */
    handleRequirement(key: string, item: object, index: number) {
        this.$set(this.requirementActive, key, index);
        console.log(this.requirementActive);

        const country_id =
            this.requirementActive.country !== undefined
                ? this.requirement.country[this.requirementActive.country].id
                : undefined;

        const field_id =
            this.requirementActive.field !== undefined
                ? this.requirement.field[this.requirementActive.field].id
                : undefined;

        this.getCaseList(country_id, field_id, undefined, this.input);
    }

    pageChange(current: any) {
        const country_id =
            this.requirementActive.country !== undefined
                ? this.requirement.country[this.requirementActive.country].id
                : undefined;

        const field_id =
            this.requirementActive.field !== undefined
                ? this.requirement.field[this.requirementActive.field].id
                : undefined;

        this.getCaseList(country_id, field_id, current,this.input);
    }

    timer: any = null;
    debounce(fn: any, wait: any) {
        return function (_this: any) {
            if (_this.timer !== null) {
                clearTimeout(_this.timer);
            }
            _this.timer = setTimeout(fn, wait);
        };
    }

    handle() {
        // console.log(Math.random());
        
        const country_id = this.requirement.country[this.requirementActive.country].id;
        const field_id = this.requirement.field[this.requirementActive.field].id;

        this.getCaseList(country_id, field_id, undefined, this.input);
    }

    input = ""; //搜索框数据

    searchChange(value: string | number) {
        this.debounce(this.handle, 1000)(this);
    }

    mounted() {
        this.getRequirement().then(() => {
            this.InitRequirementActive();
        });
        this.getCaseList(undefined, undefined, undefined);

        //  document.addEventListener("scroll",this.debounce(this.handle, 1000))
    }
}
