





















































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
import { Tabs, TabPane } from "element-ui";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb,
        "el-tabs": Tabs,
        "el-tab-pane": TabPane,
    },
})
export default class projectlist2021 extends Vue {
    field: {} = {}; //领域

    activeName: any = "1"; //tab actived
    caseListData: any = {}; //列表数据

    loading1: any = true; //是否显示loading遮罩

    handleClick(event: any) {
        this.getCaseList(event.name); //加载新的列表数据
    }

    /**获取项目领域 */
    async getRegion() {
        const getOpt = this.$api.apiList.projectType2021;
        const opt = getOpt();

        return await this.$axios({
            method: opt.method,
            url: opt.url,
            headers: opt.headers,
        }).then((res: any) => {
            return res.data.data;
        });
    }

    //请求列表数据
    getCaseList(field: any) {
        this.loading1 = false;
        const getOpt = this.$api.apiList.projectList_2021;
        const opt = getOpt(field);

        this.$axios({
            method: opt.method,
            url: opt.url,
        })
            .then((res: any) => {
                this.$set(this.caseListData, field, res.data.data);
                this.loading1 = false;
            })
            .catch(() => {
                this.loading1 = false;
            });
    }

    //点击项目跳转详情页
    handelProject(e: any, item: any, key: any) {
        this.$router.push({ name: "project2021", params: { pid: item.id } });
    }

    mounted() {
        this.getRegion().then((res: any) => {
            this.field = res;
            this.getCaseList(this.activeName);
        });
    }
}
